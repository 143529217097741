/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import EntityDropdown from "../../Common Components/Dropdowns/EntityDropdown";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import { createMatch } from "services/matches";
import {
  formatPostMatchDetailsData,
  getScorersListMapped,
  getTeamsListMapped,
} from "../helper";

import {
  leftSideColConfig,
  rightSideColConfig,
  badmintonConfig,
} from "../formConfig";
import { logFirebaseEvent } from "config/firebase";
import colors from "config/colorConfig";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function CreateMatchForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    getValues,
    setError,
    watch,
    formState: { isValid, isDirty },
  } = methods;

  const navigate = useNavigate();
  const { tournamentId } = useParams();
  const { state } = useLocation();

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false);

  const handleValidations = () => {
    const teamAId = getValues("team_a")?.id;
    const teamBId = getValues("team_b")?.id;
    const lineUpSize = Number(getValues("lineup_size"));
    // const oversPerInnings = Number(getValues("overs_per_inning"));

    if (teamAId === teamBId) {
      setError(
        "team_b",
        {
          type: "custom",
          message: "Team A and Team B can not be same!",
        },
        { shouldFocus: true }
      );
      return false;
    }

    if (lineUpSize < 1 || lineUpSize > 15) {
      setError(
        "lineup_size",
        {
          type: "custom",
          message: "Please enter a value between 1 and 15!",
        },
        { shouldFocus: true }
      );
      return false;
    }

    return true;
  };

  const formSubmitHandler = async (data: any) => {
    if (!handleValidations()) return;

    setLoaderState(true);
    const formattedData = formatPostMatchDetailsData(data);
    console.log("create formatted data: ", formattedData);
    formattedData.matchDetails["tournament_id"] = tournamentId;
    console.log("formatted data: ", formattedData);

    let result = await createMatch(formattedData);
    if (result && !result?.isError) {
      const matchId = result?.data?.match_id;
      logFirebaseEvent({
        event_name: "match_add_save",
        tournament_id: tournamentId,
        match_id: matchId,
      });
      navigate(-1);
      toast.success("Match created successfully!");
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false);
  };

  const matchSport = watch("match_sport");

  const formConfig = {
    teamAName: {
      disabled: false,
      optionsFetcher: getTeamsListMapped,
      OptionsDisplayComp: EntityDropdown,
      idParam: tournamentId,
    },
    teamBName: {
      disabled: false,
      optionsFetcher: getTeamsListMapped,
      OptionsDisplayComp: EntityDropdown,
      idParam: tournamentId,
    },
    isActive: {
      disabled: false,
    },
    sport: {
      disabled: false,
    },
    scorer: {
      disabled: false,
      optionsFetcher: getScorersListMapped,
      OptionsDisplayComp: EntityDropdown,
    },
    matchType: {
      disabled: false,
    },
    startTime: {
      disabled: false,
    },
    overs: {
      disabled: matchSport === "FOOTBALL" ? true : false,
      required: matchSport === "FOOTBALL" ? false : true,
    },
    venue: {
      disabled: false,
    },
    urlLink: {
      disabled: false,
    },
    lineUpSize: {
      disabled: false,
    },
    maxOvers: {
      disabled: false,
    },
    oversPerBowler: {
      disabled: false,
    },
    matchCategory: {
      disabled: false,
    },
    matchName: {
      disabled: false,
    },
    matchNumber: {
      disabled: false,
    },
    sequenceNumber: {
      disabled: false,
    },
  };

  return (
    <>
      <div
        className={styles["form-heading"]}
        data-testid="match-create-heading"
      >
        Add match &nbsp;
        <span className={styles["tournament_name"]}>
          {`(${state?.tournamentName})`}
        </span>
      </div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            {matchSport === "BADMINTON" && (
              <>
                <Divider sx={{ my: 3 }} />
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  BADMINTON FIELDS
                </div>
                <Grid item xs={12} md={6}>
                  <FieldMapper
                    fieldsConfig={badmintonConfig(formConfig, "create")}
                  />
                </Grid>
              </>
            )}

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty
                    ? navigate("/tournament")
                    : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save match
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreateMatchForm;
