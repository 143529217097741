/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Autocomplete,
  Box,
  FormControl,
  TextField,
  autocompleteClasses,
} from "@mui/material";
import { debounce } from "@mui/material/utils";

import {
  MultiSearchDropdownStyles,
  SelectFieldStyles,
} from "./FormUtils/FormStyles";

import styles from "./styles.module.css";
import Icon from "assets/Search.svg";

const IconPop = () => {
  return <img src={Icon} style={{ marginTop: "0.3rem" }} />;
};

export const FormMultiselectLive = (props: any) => {
  const {
    name,
    label,
    rules,
    placeholder,
    disabled,
    required,
    optionsFetcher,
    idParam,
    OptionsDisplayComp,
    populateOptions,
    onValueChange,
  } = props;

  const FieldStyles = {
    ...SelectFieldStyles,
    ...{
      [`& .${autocompleteClasses.popupIndicator}`]: {
        transform: "none",
      },
    },
  };

  const { control } = useFormContext();
  const [data, setData] = useState<any>([]);
  const [inputVal, setInputVal] = useState("");

  const fetchOptions = React.useMemo(
    () =>
      debounce(async (input) => {
        if (optionsFetcher) {
          let result = await optionsFetcher(input);
          if (result) setData(result);
        } else setData([]);
      }, 400),
    []
  );
  useEffect(() => {
    let active = true;
    
    if(populateOptions){[
      fetchOptions('')
    ]};

    if (inputVal.length >= 3) {
      fetchOptions(inputVal);
    }

    return () => {
      active = false;
    };
  }, [inputVal, fetchOptions]);

  return (
    <>
      {data ? (
        <FormControl size="small" fullWidth>
          <label className={styles["form-label"]}>
            {label}{" "}
            {required ? (
              <span className={styles["required-symbol"]}>*</span>
            ) : (
              <></>
            )}
          </label>
          <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
              return (
                <>
                  <Autocomplete
                    multiple
                    filterOptions={(x) => x}
                    includeInputInList
                    disableCloseOnSelect
                    // inputValue={inputVal}
                    clearOnBlur={false}
                    filterSelectedOptions
                    popupIcon={<IconPop />}
                    sx={FieldStyles}
                    disablePortal
                    options={data ? data : []}
                    disabled={disabled}
                    onChange={(_event, values) => {
                      onChange(values);
                      if(onValueChange)
                        onValueChange(values);
                    }}
                    getOptionLabel={(option: any) =>
                      option?.label || value?.label || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    value={value !== undefined ? value : []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={placeholder}
                        sx={MultiSearchDropdownStyles}
                        size="small"
                        error={!!error}
                        onChange={(e) => setInputVal(e.target.value)}
                      />
                    )}
                    renderOption={(props, option: any) =>
                      OptionsDisplayComp ? (
                        <OptionsDisplayComp
                          data={option}
                          {...props}
                          key={option?.id}
                        />
                      ) : (
                        <Box
                          component="li"
                          display="flex"
                          sx={{
                            fontSize: "1.4rem",
                          }}
                          {...props}
                        >
                          <div>{option?.label}</div>
                        </Box>
                      )
                    }
                  />

                  <div className={styles["error-msg"]}>
                    {error ? error.message : null}
                  </div>
                </>
              );
            }}
          />
        </FormControl>
      ) : (
        <> </>
      )}
    </>
  );
};
