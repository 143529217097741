import tournamentIcon from "assets/SideDrawer/dashboard.svg";
import teamsIcon from "assets/SideDrawer/shape.svg";
import playersIcon from "assets/SideDrawer/star-circle.svg";
import scorersIcon from "assets/SideDrawer/calendar-star.svg";
import signOutIcon from "assets/SideDrawer/SignOut.svg";

const drawerConfig = [
  {
    id: "1",
    label: "Tournaments",
    icon: tournamentIcon,
    path: "/tournament",
  },
  { id: "2", label: "Teams", icon: teamsIcon, path: "/teams" },
  {
    id: "3",
    label: "Players",
    icon: playersIcon,
    path: "/players",
  },
  {
    id: "4",
    label: "Scorers",
    icon: scorersIcon,
    path: "/scorers",
  },
  {
    id: "5",
    label: "NTPC",
    icon: scorersIcon,
    path: "/ntpc/matches/badmintonmen",
  },
  {
    id: "6",
    label: "Grounds",
    icon: scorersIcon,
    path: "/grounds",
  },
];

const logOutConfig = {
  id: "5",
  label: "Log Out",
  icon: signOutIcon,
  path: "/",
};

export { drawerConfig, logOutConfig };
