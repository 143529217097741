import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { Divider, Grid } from "@mui/material";
import { toast } from "react-toastify";

import FieldMapper from "components/Common Components/Form Fields/FormUtils/FieldMapper";
import FormButton from "components/Common Components/Buttons/FormButton";
import BasicModal from "components/Common Components/Modal/BasicModal";
import DiscardModal from "components/Common Components/Modal/DiscardModal";

import { createTournament } from "services/touranments";
import { leftSideColConfig, rightSideColConfig } from "../formConfig";
import colors from "config/colorConfig";
import { logFirebaseEvent } from "config/firebase";
import styles from "../styles.module.css";
import BackdropLoader from "components/Common Components/Loaders/LoaderBackdrop";

function CreateTournamentForm() {
  const methods = useForm({
    criteriaMode: "firstError",
    mode: "onBlur",
    shouldFocusError: true,
  });
  const {
    handleSubmit,
    setError,
    reset,
    getValues,
    formState: { isValid, isDirty },
  } = methods;

  const navigate = useNavigate();

  const [modalState, setModalState] = useState(false);
  const [loaderState, setLoaderState] = useState(false)

  const handleValidations = () => {
    if (
      new Date(getValues("end_date")).getTime() <
      new Date(getValues("start_date")).getTime()
    ) {
      setError(
        "end_date",
        {
          type: "custom",
          message: "End date cannot be earlier than start date!",
        },
        { shouldFocus: true }
      );
      return false;
    }
    return true;
  };

  const formSubmitHandler = async (data: any) => {
    if (!handleValidations()) return;
    
    setLoaderState(true)
    const result = await createTournament(data);
    if (result && !result?.isError) {
      const tournament_id = result?.data?.tournament_id;
      logFirebaseEvent({
        event_name: "tournament_add_save",
        tournament_id: tournament_id,
      });
      navigate("/tournament");
      toast.success("Successfully created!");
      reset();
    } else {
      toast.error(result?.reason);
    }
    setLoaderState(false)
  };

  const formConfig = {
    tName: {
      disabled: false,
    },
    startDate: {
      disabled: false,
    },
    endDate: {
      disabled: false,
    },
    format: {
      disabled: false,
    },
    isActive: {
      disabled: false,
    },
    type: {
      disabled: false,
    },
    ballType: {
      disabled: false,
    },
    playerOfSeries: {
      disabled: true,
    },
    uploadPhoto: {
      disabled: false,
    },
    displayFormat: {
      disabled: false,
    },
    hostCity: {
      disabled: false,
    },
    sport: {
      disabled: false,
    },
    isSubstitutionAllowed: {
      disabled: false
    },
  };

  return (
    <>
      <div
        className={styles["form-heading"]}
        data-testid="tournament-create-heading"
      >
        Add tournament
      </div>

      <div className={styles["form-container"]}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(formSubmitHandler)}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={leftSideColConfig(formConfig, "create")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FieldMapper
                  fieldsConfig={rightSideColConfig(formConfig, "create")}
                />
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            <Grid container item columnGap={3}>
              <FormButton
                type="button"
                fontColor={colors.redSixty}
                backgroundColor={colors.white}
                borderColor={colors.grayThirty}
                onClick={() =>
                  !isDirty
                    ? navigate("/tournament")
                    : setModalState(!modalState)
                }
              >
                Discard
              </FormButton>

              <FormButton
                type="submit"
                fontColor={colors.white}
                backgroundColor={colors.grayNinety}
                disabledColor={colors.grayFifty}
                disabledBackground={colors.grayTwenty}
                disabled={!isValid}
              >
                Save tournament
              </FormButton>
            </Grid>
          </form>
        </FormProvider>
      </div>
      <BasicModal modalState={modalState} handleModalState={setModalState}>
        <DiscardModal
          modalState={modalState}
          handleModalState={setModalState}
        />
      </BasicModal>
      <BackdropLoader loaderState={loaderState} />
    </>
  );
}

export default CreateTournamentForm;
