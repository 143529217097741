import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import moment from "moment";
import {
  getAllScorers,
  getTeamListByTournament,
  getTeamsListByName,
} from "services/matches";
import { matchAttributesList, matchAttributesMap } from "utils/constants";

dayjs.extend(utc);

export const getTeamsListMapped = async (searchVal: string) => {
  let result = await getTeamsListByName(searchVal);
  if (result && !result?.isError) {
    return result?.data?.map((team: any) => {
      return {
        id: team?.team_id,
        label: team?.name,
        location: team?.team_city,
        image: team?.image_url,
      };
    });
  }
  return [];
};

export const getScorersListMapped = async (val?: string) => {
  let result = await getAllScorers();
  if (result && !result?.isError) {
    return result?.data?.map((scorer: any) => {
      return {
        id: scorer?.scorerId,
        label: scorer?.name,
        image: scorer?.imageUrl,
      };
    });
  }
  return [];
};

export const formatPostMatchDetailsData = (data: any) => {
  const startTime = dayjs
    .utc(data["start_time"].toUTCString())
    .format("YYYY-MM-DD HH:mm:ss");
  delete data?.id;
  delete data?.status;
  const matchDetails = {
    ...data,
    start_time: startTime,
    team_a: data?.team_a?.id,
    team_b: data?.team_b?.id,
    scorer_id: data?.scorer_id?.id,
    is_active: Number(data?.is_active),
    lineup_size: Number(data?.lineup_size),
    // no_of_overs: Number(data?.no_of_overs),
  };

  const matchAttributes = createMatchAttributes(data);

  return {
    matchDetails,
    matchAttributes,
  };
};

export const formatGetMatchDetailsData = (data: any) => {
  const matchAttributes = getMatchAttributesParsed(data?.matchAttributes);

  const matchData = {
    ...data.matchDetails,
    ...matchAttributes,
    ...data,
  };

  const objectEntries = Object.entries(matchData) as [string, any][];
  let finalData = matchData;

  for (const [key, value] of objectEntries) {
    if (key === "start_time") {
      if (typeof value === "string") {
        // let localTime = dayjs(value).local().toDate()
        let localTime = formatDateToISTinDate(value).toDate();
        // console.log(moment(value).toDate(), "--------", formatDateToISTinDate(value).toDate(), "moment")
        finalData[key] = localTime;
        // finalData[key] = parseISO(value);
      }
    }

    if (key === "teamADetails") {
      finalData["team_a"] = mapSingleTeamKeys(value);
    }

    if (key === "teamBDetails") {
      finalData["team_b"] = mapSingleTeamKeys(value);
    }

    if (key === "scorerInfo") {
      finalData["scorer_id"] = {
        image: value?.imageUrl,
        label: value?.name,
        id: value?.scorerId,
      };
    }
  }

  delete finalData?.createdAt;
  delete finalData?.updatedAt;
  delete finalData?.teamADetails;
  delete finalData?.teamBDetails;
  delete finalData?.scorerInfo;
  delete finalData?.tournamentInfo;

  delete finalData?.matchAttributes;
  delete finalData?.matchDetails;

  return finalData;
};

export const mapSingleTeamKeys = (teamObj: any) => {
  return {
    id: teamObj?.team_id,
    label: teamObj?.name,
    image: teamObj?.image_url,
    location: teamObj?.team_city,
  };
};

export const getTeamsListMappedByTournament = async (tournamentId: string) => {
  let result = await getTeamListByTournament(tournamentId);
  if (result && !result?.isError) {
    return result?.data?.map((team: any) => {
      return {
        id: team?.team_id,
        label: team?.name,
        location: team?.team_city,
        image: team?.image_url,
      };
    });
  }
  return [];
};

export function formateDateToUTC(date: any) {
  return moment.utc(moment(date));
}

export function formateDateToIST(date: any, format = "YYYY-MM-DDTHH:mm") {
  return moment(date).format(format);
}

export const formatDateToISTinDate = (
  date: any,
  format = "YYYY-MM-DDTHH:mm"
) => {
  return moment(date).utcOffset("+05:30");
};

export function getMatchAttributesParsed(dataList: any) {
  // format and return
  const response: any = {};
  if (!dataList || dataList?.length === 0) return response;

  // operation
  dataList?.map((item: any) => {
    const key = matchAttributesMap[item?.attributeName];
    const value = item?.attributeValue;
    // response[key] = matchAttributesList.includes(key) ? Number(value) : value; // TODO: To update this into string, since field can be string as well.
    response[key] = matchAttributesList.includes(key)
      ? key === "no_of_overs" || key === "max_overs_per_bowler"
        ? Number(value)
        : value
      : value;

    return true;
  });

  return response;
}

export function createMatchAttributes(data: any) {
  const response: any[] = [];
  Object?.keys(data)?.map((item: any) => {
    if (matchAttributesList.includes(item)) {
      response.push({
        attributeName: item,
        attributeValue: data[item],
      });
    }
    return true;
  });

  return response;
}
