const ENV: any = process.env.REACT_APP_ENV || "development";

const baseUrl: any = {
  development: "https://test.pavilionplay.com",
  staging: "https://test.pavilionplay.com",
  production: "https://api.pavilionplay.com",
  // production:
  //   "http://prd-scoring-backend-lb-1968036550.ap-south-1.elb.amazonaws.com",
};

const apiEndpoints = {
  //AUTH FLOW
  adminLogin: "/api/v1/admin/login",
  adminVerifyOtp: "/api/v1/admin/login/verifyOtp",
  adminResendOtp: "/api/v1/admin/login/resendOtp",

  //TOURNAMENTS
  tournamentsPaginatedList: "/api/v1/admin/tournaments/list",
  createTournament: "/api/v1/admin/tournaments",
  getTournamentById: "/api/v1/admin/tournaments",
  getPlayersByTournament: "/api/v1/admin/tournament-players",
  updateTournamentStatus: "/api/v1/admin/tournaments",
  cancelTournament: "/api/v1/admin/tournaments-cancel",
  uploadAsset: "/api/v1/admin/upload",
  createBulkTournaments: "/api/v1/admin/bulk/tournaments",
  getTournamentTeams: "/api/v1/tournament/tournament-teams",

  //MATCHES
  matchesPaginatedList: "/api/v1/admin/matches/list",
  getMatchById: "/api/v1/admin/matches",
  createMatch: "/api/v1/admin/matches",
  getTeamsByTournament: "/api/v1/admin/teams-by-tournament",
  getTeamsByName: "/api/v1/admin/teams-by-name",
  updateMatchStatus: "/api/v1/admin/matches",
  createBulkMatches: "/api/v1/admin/bulk/matches",

  // Ntpc Matches
  createNtpcMatch: "/api/v1/third-party-match/create",
  getNtpcMatchesList: "/api/v1/third-party-match/getMatchList",
  getNtpcMatchDetailsById: "/api/v1/third-party-match/getMatchDetails",
  editNtpcMatch: "/api/v1/third-party-match/update",

  //TEAMS
  teamsPaginatedList: "/api/v1/admin/teams/list",
  getTeamById: "/api/v1/admin/teams",
  createTeam: "/api/v1/admin/teams",
  createBulkTeams: "/api/v1/admin/bulk/teams",
  getPlayersbyTeam: "/api/v1/admin/players-by-team",

  //PLAYERS
  playersListPaginated: "/api/v1/admin/players/list",
  getPlayerById: "/api/v1/admin/players",
  createPlayer: "/api/v1/admin/players",
  createBulkPlayers: "/api/v1/admin/bulk/players",

  //SCORER
  getAllScorers: "/api/v1/admin/scorers-list",
  scorersPaginatedList: "/api/v1/admin/scorers/list",
  createScorer: "/api/v1/admin/scorers",
  getScorerById: "/api/v1/admin/scorers",
  editScorer: "/api/v1/admin/scorers",
  getMatchesByScorerId: "/api/v1/admin/scorer-matches",

  // Grounds
  groundsPaginatedList: "/api/v1/admin/venue/list",
  createGround: "/api/v1/admin/venue",
  getGroundById: "/api/v1/admin/venue",
  editGround: "/api/v1/admin/venue",

  // LOCATION
  getAllStates: "/api/v1/admin/location/all-states",
  getCitiesInState: "/api/v1/admin/location/cities",
};

export { ENV, baseUrl, apiEndpoints };
